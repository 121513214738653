import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { DesignFrontendSettings } from '../api/models/design-frontend-settings';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';

@Directive({
    selector: '[btnPrimaryColorDirective]',
    standalone: true
})
export class BtnPrimaryColorDirective implements OnInit, OnDestroy {
    color: string;
    sub: Subscription = new Subscription();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private applicationSettingsService: ApplicationSettingsService
    ) {}

    ngOnInit(): void {
        this.initialize();
        this.renderer.setStyle(this.el.nativeElement, 'background-color', this.color);
        this.renderer.setStyle(this.el.nativeElement, 'border-color', this.color);
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.color = settings?.color_primary_button ?? '';
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    @HostListener('mouseover') onMouseOver() {
        const darkColor = this.adjust(this.color, -50);
        this.renderer.setStyle(this.el.nativeElement, 'background-color', darkColor);
        this.renderer.setStyle(this.el.nativeElement, 'border-color', darkColor);
    }

    @HostListener('mouseout') onMouseOut() {
        this.renderer.setStyle(this.el.nativeElement, 'background-color', this.color);
        this.renderer.setStyle(this.el.nativeElement, 'border-color', this.color);
    }

    // function to programmatically lighten or darken a hex color by a specific amount. To darken, pass in a negative number
    adjust(color: string, amount: number) {
        return (
            '#' +
            color
                .replace(/^#/, '')
                .replace(/../g, (color) =>
                    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
                )
        );
    }
}
