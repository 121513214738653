import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';
import { DesignFrontendSettings } from '../../api/models/design-frontend-settings';

@Component({
    selector: 'otb-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss', './footer.component.mags-sop.scss']
})
export class FooterComponent implements OnDestroy {
    sub: Subscription = new Subscription();
    footerColor = '';

    constructor(
        private translateService: TranslateService,
        private applicationSettingsService: ApplicationSettingsService
    ) {}

    ngOnInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.footerColor = settings?.color_footer ?? '';
                }
            )
        );
    }

    getFooterColor(): string | null {
        return this.footerColor?.trim() || null;
    }

    generateAriaLabel(valueTranslationKey: string, ariaLabelTranslationKey: string): string {
        return (
            this.translateService.instant(valueTranslationKey) +
            ' - ' +
            this.translateService.instant(ariaLabelTranslationKey)
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
