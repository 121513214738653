import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DesignFrontendSettings } from '../../api/models/design-frontend-settings';

@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsService {
    serviceOnly: boolean = false;
    onlineService: boolean = false;

    showEasyLanguagePageSubject = new BehaviorSubject<boolean>(false);
    showUploadedLogoSubject = new BehaviorSubject<boolean>(false);
    designFrontendSettingsSubject = new BehaviorSubject<DesignFrontendSettings>({
        color_header1: '',
        color_header2: '',
        color_footer: '',
        color_progress_bar: '',
        color_primary_button: ''
    });
    applicationSettingsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    errorLoadingApplicationSettings: WritableSignal<boolean> = signal(false);
    constructor() {}

    toJson(): object {
        return {
            serviceOnly: this.serviceOnly,
            onlineService: this.onlineService,
            showEasyLanguagePage: this.showEasyLanguagePageSubject.value,
            showUploadedLogo: this.showUploadedLogoSubject.value,
            designFrontendSettings: this.designFrontendSettingsSubject.value
        };
    }

    parseFromJson(json: {
        serviceOnly?: boolean;
        onlineService?: boolean;
        showEasyLanguagePage?: boolean;
        showUploadedLogo?: boolean;
        designFrontendSettings?: DesignFrontendSettings;
    }): any {
        const { serviceOnly, onlineService, showEasyLanguagePage, showUploadedLogo, designFrontendSettings } = json;

        if (serviceOnly !== undefined) {
            this.serviceOnly = serviceOnly;
        }

        if (onlineService !== undefined) {
            this.onlineService = onlineService;
        }

        if (showEasyLanguagePage !== undefined) {
            this.showEasyLanguagePageSubject.next(showEasyLanguagePage);
        }

        if (showUploadedLogo !== undefined) {
            this.showUploadedLogoSubject.next(showUploadedLogo);
        }

        if (designFrontendSettings !== undefined) {
            this.designFrontendSettingsSubject.next(designFrontendSettings);
        }

        return json;
    }
}
