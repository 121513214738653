import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';
import { DesignFrontendSettings } from '../../../api/models/design-frontend-settings';

@Component({
    selector: 'otb-footer-sh',
    templateUrl: './footer-sh.component.html',
    styleUrls: ['./footer-sh.component.scss']
})
export class FooterShComponent implements OnDestroy {
    sub: Subscription = new Subscription();
    footerColor = '';

    constructor(private applicationSettingsService: ApplicationSettingsService) {}

    ngOnInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.footerColor = settings?.color_footer ?? '';
                }
            )
        );
    }

    getFooterColor(): string | null {
        return this.footerColor?.trim() || null;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
