import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressBarService } from '../../services/progress-bar/progress-bar.service';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';
import { DesignFrontendSettings } from '../../api/models/design-frontend-settings';

@Component({
    selector: 'otb-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: [
        './progress-bar.component.scss',
        './progress-bar.component.mags-sop.scss',
        './progress-bar.component.sh.scss'
    ]
})
export class ProgressBarComponent implements OnDestroy {
    currentStep: number = 0;
    totalSteps: number = 5;
    progress: string = '';
    show: boolean = true;
    sub: Subscription = new Subscription();
    progressBarColor = '';

    constructor(
        private progressbarService: ProgressBarService,
        private applicationSettingsService: ApplicationSettingsService
    ) {
        this.progress = this.progressbarService.progress;
        this.currentStep = this.progressbarService.currentStep;
        this.totalSteps = this.progressbarService.totalSteps;
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.progressBarColor = settings?.color_progress_bar ?? '';
                }
            )
        );
    }

    getProgressBarColor(): string | null {
        return this.progressBarColor?.trim() || null;
    }
}
